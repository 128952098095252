const WalkThroughData = [
  {
    id: 1,
    cover: require("../../Assets/Walkthrough/10.png"),
    alttag: "grandthum bhutani group",
  },
  {
    id: 2,
    cover: require("../../Assets/Walkthrough/11.png"),
    alttag: "grandthum office space",
  },
  {
    id: 3,
    cover: require("../../Assets/Walkthrough/12.png"),
    alttag: "bhutani grandthum office space",
  },
  {
    id: 2,
    cover: require("../../Assets/Walkthrough/2.png"),
    alttag: "grandthum bhutani group",
  },
  
];

export default WalkThroughData;
