const FloorPlanData = [
    {
        id:1,
        cover1: require("../../Assets/FloorPlan/office-floor-plans-tower-A-scaled.jpg"),
        alttag1:"grandthum bhutani",
        cover2: require("../../Assets/FloorPlan/office-floor-plans-tower-B-scaled.jpg"),
        alttag2:"grandthum bhutani group",
    },
    // {
    //     id:2,
    //     cover1: require("../../Assets/FloorPlan/floor5-1.jpg"),
    //     alttag1:"grandthum office space",
    //     cover2: require("../../Assets/FloorPlan/floor5-2.jpg"),
    //     alttag2:"bhutani grandthum office space",
    // },
    // {
    //     id:3,
    //     cover1: require("../../Assets/FloorPlan/floor1-1.jpg"),
    //     alttag1:"bhutani grandthum possession",
    //     cover2: require("../../Assets/FloorPlan/floor1-2.jpg"),
    //     alttag2:"bhutani grandthum construction update",
    // },
    // {
    //     id:4,
    //     cover1: require("../../Assets/FloorPlan/floor2-1.jpg"),
    //     alttag1:"bhutani grandthum price",
    //     cover2: require("../../Assets/FloorPlan/floor2-2.jpg"),
    //     alttag2:"bhutani grandthum price list",
    // },
    // {
    //     id:5,
    //     cover1: require("../../Assets/FloorPlan/floor3-1.jpg"),
    //     alttag1:"bhutani grandthum",
    //     cover2: require("../../Assets/FloorPlan/floor3-2.jpg"),
    //     alttag2:"bhutani grandthum price",
    // },
]

export default FloorPlanData;